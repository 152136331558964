/** @module NotFoundPage
 *  @since 2022.02.08, 22:28
 *  @changed 2022.02.08, 22:28
 */

// TODO 2022.02.09, 21:51 -- Use `_error` page? See `https://nextjs.org/docs/advanced-features/custom-error-page`.

import React from 'react'
// import { Box, Heading } from '@chakra-ui/react'
// import * as siteConfig from '@config/site'
// import TopSplash from '@components/TopSplash/TopSplash'
import GenericPageLayout from '@layout/GenericPageLayout'
import { subPageTitle } from '@ui-support/pageUtils'
import NotFoundSection, { title as pageTitle } from '@components/NotFound/Section'

function NotFoundPage() {
  // const pageTitle = 'Почему выгодно покупать квартиру с нами'
  const title = subPageTitle(pageTitle)
  return (
    <GenericPageLayout title={title}>
      <NotFoundSection />
    </GenericPageLayout>
  )
}

export default NotFoundPage
