/** @module NotFoundContent
 *  @since 2022.02.08, 22:44
 *  @changed 2022.02.08, 22:44
 */

import React from 'react'
import { useRouter } from 'next/router'
import { Box, Text } from '@chakra-ui/react'
import classnames from 'classnames'
import { TReactContent } from '@utils/react-types'
// import config from '@config'
import PageLink from '@ui-support/PageLink'

import styles from './NotFoundContent.module.less'

export const title = 'Страница не найдена'

interface TNotFoundContentProps {
  className?: string
}
function NotFoundContent(props: TNotFoundContentProps) {
  const { className } = props
  const router = useRouter()
  const { asPath } = router
  return (
    <Box className={classnames(className, styles.root)}>
      <Text my="4">
        Страница <u>{asPath}</u> отсутствует на сайте: возможно, введён неверный адрес или запрашиваемый ресурс был
        удален или переименован.
      </Text>
      <Text my="4">
        Попробуйте найти нужную информацию на <PageLink href="/">главной странице</PageLink>.
      </Text>
    </Box>
  )
}

export default NotFoundContent
